

// import React, { useState, useEffect, Suspense, lazy } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import SideNavbar from './Pages/SideNavbar';
// import Header from './Pages/Header';
// import Login from './Pages/Login'; 

// const Dashboard = lazy(() => import('./components/Dashboard'));
// const About = lazy(() => import('./components/About'));
// const Contact = lazy(() => import('./components/Contact'));
// const Blog = lazy(() => import('./components/Blog'));
// const Far = lazy(() => import('./components/Far'));
// const RoadMap = lazy(() => import('./components/RoadMap'));
// const Landing = lazy(() => import('./Pages/Landing'));
// const HomeAbout = lazy(() => import('./Pages/HomeAbout'));
// const Testimonials = lazy(() => import('./Pages/Testimonials'));
// const SliderImage = lazy(() => import('./Pages/SliderImage'));
// const SocialMedia = lazy(() => import('./Pages/SocialMedia'));
// const Socalicon = lazy(() => import('./Pages/Socalicon'));


// function App() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const toggleSidebar = () => setIsOpen(!isOpen);

//   useEffect(() => {
//     const token = localStorage.getItem('authToken');
//     if (token) {
//       setIsAuthenticated(true);
//     } else {
//       setIsAuthenticated(false);
//     }
//   }, []);

//   return (
//     <Router>
//       <div className="relative flex h-screen overflow-hidden">
//         {/* Sidebar */}
//         {isAuthenticated && <SideNavbar isOpen={isOpen } toggleSidebar={toggleSidebar} />}

//         {/* Main content */}
//         <div className={`flex-1 transition-all duration-300 ${isOpen ? 'ml-[250px]' : 'ml-[50px]'}`}>
//           <Header toggleSidebar={toggleSidebar} />
//           <main className="p-2 h-full overflow-auto">
//             <Suspense fallback={<div>Loading...</div>}>
//               <Routes>
//                 <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
//                 <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/dashboard" />} />
//                 <Route path="/about" element={isAuthenticated ? <About /> : <Navigate to="/about" />} />
//                 <Route path="/contact" element={isAuthenticated ? <Contact /> : <Navigate to="/contact" />} />
//                 <Route path="/blog" element={isAuthenticated ? <Blog /> : <Navigate to="/blog" />} />
//                 <Route path="/far" element={isAuthenticated ? <Far /> : <Navigate to="/far" />} />
//                 <Route path="/roadMap" element={isAuthenticated ? <RoadMap /> : <Navigate to="/roadMap" />} />
//                 <Route path="/landing" element={isAuthenticated ? <Landing /> : <Navigate to="/landing" />} />
//                 <Route path="/homeAbout" element={isAuthenticated ? <HomeAbout /> : <Navigate to="/homeAbout" />} />
//                 <Route path="/testimonials" element={isAuthenticated ? <Testimonials /> : <Navigate to="/testimonials" />} />
//                 <Route path="/sliderImage" element={isAuthenticated ? <SliderImage /> : <Navigate to="/sliderImage" />} />
//                 <Route path="/socialMedia" element={isAuthenticated ? <SocialMedia/> : <Navigate to="/socialMedia" />} />
//                 <Route path="/socalicon" element={isAuthenticated ? <Socalicon /> : <Navigate to="/socalicon" />} />
   

//               </Routes>
//             </Suspense>
//           </main>
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;



import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SideNavbar from './Pages/SideNavbar';
import Header from './Pages/Header';
import Login from './Pages/Login';

const Dashboard = lazy(() => import('./components/Dashboard'));
const About = lazy(() => import('./components/About'));
const Contact = lazy(() => import('./components/Contact'));
const Blog = lazy(() => import('./components/Blog'));
const Far = lazy(() => import('./components/Far'));
const RoadMap = lazy(() => import('./components/RoadMap'));
const Landing = lazy(() => import('./Pages/Landing'));
const HomeAbout = lazy(() => import('./Pages/HomeAbout'));
const Testimonials = lazy(() => import('./Pages/Testimonials'));
const SliderImage = lazy(() => import('./Pages/SliderImage'));
const SocialMedia = lazy(() => import('./Pages/SocialMedia'));
const Socalicon = lazy(() => import('./Pages/Socalicon'));

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    setIsAuthenticated(!!token);
  }, []);

  return (
    <Router>
      <div className="relative flex h-screen overflow-hidden">
        {/* Sidebar */}
        {isAuthenticated && <SideNavbar isOpen={isOpen} toggleSidebar={toggleSidebar} />}

        {/* Main content */}
        <div
          className={`flex-1 transition-all duration-300 ${
            isAuthenticated ? (isOpen ? 'ml-[250px]' : 'ml-[50px]') : ''
          }`}
        >
          {isAuthenticated && <Header toggleSidebar={toggleSidebar} />}
          <main className="p-2 h-full overflow-auto">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route
                  path="/"
                  element={
                    isAuthenticated ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <Login setIsAuthenticated={setIsAuthenticated} />
                    )
                  }
                />
                <Route
                  path="/dashboard"
                  element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
                />
                <Route
                  path="/about"
                  element={isAuthenticated ? <About /> : <Navigate to="/about" />}
                />
                <Route
                  path="/contact"
                  element={isAuthenticated ? <Contact /> : <Navigate to="/contact" />}
                />
                <Route
                  path="/blog"
                  element={isAuthenticated ? <Blog /> : <Navigate to="/blog" />}
                />
                <Route
                  path="/far"
                  element={isAuthenticated ? <Far /> : <Navigate to="/far" />}
                />
                <Route
                  path="/roadMap"
                  element={isAuthenticated ? <RoadMap /> : <Navigate to="/roadMap" />}
                />
                <Route
                  path="/landing"
                  element={isAuthenticated ? <Landing /> : <Navigate to="/landing" />}
                />
                <Route
                  path="/homeAbout"
                  element={isAuthenticated ? <HomeAbout /> : <Navigate to="/homeAbout" />}
                />
                <Route
                  path="/testimonials"
                  element={isAuthenticated ? <Testimonials /> : <Navigate to="/testimonials" />}
                />
                <Route
                  path="/sliderImage"
                  element={isAuthenticated ? <SliderImage /> : <Navigate to="/sliderImage" />}
                />
                <Route
                  path="/socialMedia"
                  element={isAuthenticated ? <SocialMedia /> : <Navigate to="/socialMedia" />}
                />
                <Route
                  path="/socalicon"
                  element={isAuthenticated ? <Socalicon /> : <Navigate to="/socalicon" />}
                />
              </Routes>
            </Suspense>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
