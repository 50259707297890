import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaTimes, FaChevronDown, FaChevronUp, FaBars } from 'react-icons/fa';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { IoIosHome } from 'react-icons/io';
import { FaBezierCurve } from "react-icons/fa";
import { IoIosContact } from "react-icons/io";
import { TbBrandBlogger } from "react-icons/tb";
import { FaAllergies } from "react-icons/fa";
import { FaRoadCircleCheck } from "react-icons/fa6";

function SideNavbar({ isOpen, toggleSidebar }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/'); 
    window.location.reload(); 
  };

  const sideNavRoutes = [
    { path: '/', name: 'Dashboard', icon: MdOutlineDashboardCustomize },
    {
      name: 'Home',
      icon: IoIosHome,
      children: [
        { path: '/landing', name: 'Landing' },
        { path: '/sliderImage', name: 'SliderImage' },
        { path: '/testimonials', name: 'Testimonials' },
        // { path: '/homeAbout', name: 'HomeAbout' },
        // { path: '/socialMedia', name: 'SocialMedia' },
        { path: '/socalicon', name: 'Socalicon' }
      ]
    },
    { path: '/about', name: 'About', icon: FaBezierCurve },
    { path: '/contact', name: 'Contact', icon: IoIosContact },
    { path: '/blog', name: 'Blog', icon: TbBrandBlogger },
    { path: '/far', name: 'FAQ', icon: FaAllergies },
    { path: '/roadMap', name: 'RoadMap', icon: FaRoadCircleCheck },
  ];

  return (
    <div className={`fixed top-0 left-0 z-50 h-full bg-[#D41D1D] transition-transform duration-300 ease-in-out ${isOpen ? 'w-[250px]' : 'w-[70px]'} overflow-hidden`}>
      <div className="flex justify-end py-6 px-6 bg-[#D41D1D] text-white">
        <button onClick={toggleSidebar} className="text-xl">
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      <nav className={`flex flex-col px-2 space-y-4 mt-4 ${isOpen ? 'opacity-100' : 'opacity-100'}`}>
        {sideNavRoutes.map((route) => {
          if (route.children) {
            return (
              <div key={route.name}>
                <button
                  onClick={toggleDropdown}
                  className="flex items-center gap-3 py-2 text-white hover:bg-[#B31616] rounded px-2 w-full text-left transition"
                >
                  <route.icon className="text-xl" />
                  {isOpen && <span className="text-sm font-bold">{route.name}</span>}
                  {isDropdownOpen ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
                </button>
                {isDropdownOpen && isOpen && (
                  <div className="pl-6">
                    {route.children.map((childRoute) => (
                      <Link
                        key={childRoute.path}
                        to={childRoute.path}
                        className="flex items-center gap-3 py-2 text-white hover:bg-[#B31616] rounded px-2 transition"
                        onClick={toggleSidebar}
                      >
                        <span className="text-sm">{childRoute.name}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <Link
                key={route.path}
                to={route.path}
                className="flex items-center gap-3 py-2 text-white hover:bg-[#B31616] rounded px-2 transition"
                onClick={toggleSidebar}
              >
                <route.icon className="text-xl" />
                {isOpen && <span className="text-sm font-bold">{route.name}</span>}
              </Link>
            );
          }
        })}

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="flex items-center gap-3 py-2 text-white hover:bg-[#B31616] rounded px-2 transition mt-auto"
        >
          <span className="text-sm font-bold">Logout</span>
        </button>
      </nav>
    </div>
  );
}

export default SideNavbar;










