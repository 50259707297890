





import React from 'react';
// import { FaBars } from 'react-icons/fa';
import logo from '../img/Asset 5@2x 1.png';

function Header() {
 

  return (
    <div className=" lg:p-2 p-4 text-black flex justify-end items-center  bg-white shadow-md ">
     
      <div className="w-16 lg:h-16  lg:mr-8 h-10 rounded-full">
        <img src={logo} alt="Fit logo" className="w-32 md:w-40 ml-auto md:mr-auto" loading="lazy" />
      </div>
    </div>
  );
}

export default Header;
























