
// export const API_BASE_URL = 'https://studentreportcard-b09df-default-rtdb.firebaseio.com/';












// export const API_BASE_URL = 'http://localhost:5000';

export const API_BASE_URL = 'https://api.fitespero.fitespero.com';
