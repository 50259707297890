


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {API_BASE_URL} from '../config/api'

const Login = ({ setIsAuthenticated }) => {
  const [value, setValue] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const res = await axios.post(`${API_BASE_URL}/api/users/login`, value);
  
      if (res.data.status === 'success') {
        localStorage.setItem('authToken', res.data.data.token); 
        setIsAuthenticated(true); 
        navigate('/dashboard'); 
      } else {
        setError(res.data.message || 'Login failed');
      }
    } catch (error) {
      console.error("Login error:", error);
      setError('An error occurred during login');
    }
  };
  

  return (
    <div className="flex justify-center items-center font-secondary min-h-screen bg-gradient-to-r from-gray-100 to-gray-200">
      <div className="bg-white p-10 rounded-xl shadow-lg w-full max-w-md transform transition-all duration-300 hover:shadow-2xl">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6 text-center">Welcome Back </h1>
       
        <p className="text-center text-gray-600 mb-8">Please login to your account</p>
        <form onSubmit={handleLogin} className="space-y-6">
          <input
            type="email"
            placeholder="Email"
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-[1px] focus:ring-red-500 focus:border-red-500 transition duration-300"
            onChange={(e) => setValue({ ...value, email: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="Password"
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-[1px] focus:ring-red-500 focus:border-red-500 transition duration-300"
            onChange={(e) => setValue({ ...value, password: e.target.value })}
            required
          />
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
          <button
            type="submit"
            className="w-full p-4 mt-6 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 shadow-md hover:shadow-xl transform transition duration-300"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
